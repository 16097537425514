@tailwind base;
@tailwind components;
@tailwind utilities;
/* styles.css */
body {
  font-family: 'Poppins', sans-serif;
}

.custom-slide {
  position: relative;
  height: 85vh;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
}
.try {
  display: none;
}











@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  animation: scroll 10s linear infinite;
}


@media (min-width:1024px){
  .try{
    display: flex;
  }
}
*{
    font-family: 'Poppins';
}