@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
}
html {
  overflow-y: scroll; /* Reserve scrollbar space */
}

/* Custom styles for slick dots */
.slick-dots-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.slick-dots {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.slick-dot button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #d3d3d3; /* Inactive dot border */
  transition: background-color 0.3s, border-color 0.3s;
}

.slick-dot.slick-active button {
  background-color: #f97316; /* Active dot color */
  border-color: #f97316; /* Active dot border color */
}

.slick-dot button:hover {
  border-color: #f97316; /* Hover border color */
}
